import { createApi } from '@reduxjs/toolkit/query/react';
/**
 * Slice
 * */
import { logout, setUser } from '../../features/user/slice';

/**
 * API
 * */
import customFetchBase from '../customFetchBase';

/**
 * Interface
 * */
import { IUser } from '../users/interfaces/user.interface';
import { IApiResponse } from '@/shared/types/app';
import { EAccount } from './enums/account.enum';
import { IAccount } from './interfaces/account.interface';

/**
 * Navigation
 * */
import { GlRoutes } from '@/navigation';

/**
 * Enum
 * */
import { ENavigationKeys } from '@/navigation/enums/navigation-keys.enum';

/**
 * Type
 * */
import { TNavItemWithChildren } from '@/navigation/types/nav-item.type';

export const accountApi = createApi({
  reducerPath: 'accountApi',
  baseQuery: customFetchBase,
  tagTypes: [EAccount.TagsType.USER],
  endpoints: build => ({
    getMe: build.query<IUser.user, null>({
      query() {
        return {
          url: '/auth/account/me',
          credentials: 'include',
        };
      },
      providesTags: () => [
        { type: EAccount.TagsType.USER, id: EAccount.IdsType.CURRENT },
      ],
      transformResponse: (result: IApiResponse<IUser.user>) => {
        return result.data;
      },
      async onQueryStarted(args, api): Promise<void> {
        try {
          const { data } = await api.queryFulfilled;
          api.dispatch(setUser(data));
        } catch (error) {
          api.dispatch(logout());
          if (!window.location.pathname.includes(ENavigationKeys.SignIn)) {
            const route = GlRoutes.findRouteByKey(
              ENavigationKeys.SignIn,
            ) as TNavItemWithChildren;
            window.location.href = route.href;
          }
        }
      },
    }),
    patchPersonalData: build.mutation<
      IUser.UserPersonalData,
      IAccount.PPatchPersonalData
    >({
      query(args) {
        return {
          url: `/users/update-personal-data/${args.userId}`,
          method: 'PATCH',
          body: {
            aboutMe: { rate: args.rate },
          },
        };
      },
      transformResponse: (result: IApiResponse<IUser.UserPersonalData>) => {
        return result.data;
      },
      invalidatesTags: [
        { type: EAccount.TagsType.USER, id: EAccount.IdsType.CURRENT },
      ],
    }),
    updateUserAgreement: build.mutation<IUser.user, void>({
      query() {
        return {
          url: '/users/agreed-with-terms',
          method: 'POST',
        };
      },
      transformResponse: (result: IApiResponse<IUser.user>) => {
        return result.data;
      },
      invalidatesTags: [
        { type: EAccount.TagsType.USER, id: EAccount.IdsType.CURRENT },
      ],
    }),
    updateUser: build.mutation<
      IUser.user,
      IAccount.PUpdateUser & IAccount.PPatchPersonalData
    >({
      query({ userId, firstName, lastName }) {
        return {
          url: `/users/${userId}`,
          method: 'PATCH',
          body: { firstName, lastName },
        };
      },
      transformResponse: (result: IApiResponse<IUser.user>) => {
        return result.data;
      },
      async onQueryStarted({ userId, bio }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;

          await dispatch(
            accountApi.endpoints.patchPersonalData.initiate({ userId, bio }),
          );
        } catch (error) {
          console.log(error);
        }
      },
      invalidatesTags: [
        { type: EAccount.TagsType.USER, id: EAccount.IdsType.CURRENT },
      ],
    }),
    deleteUser: build.mutation<number, number>({
      query(userId) {
        return {
          url: `/users/${userId}`,
          method: 'DELETE',
        };
      },
      transformResponse: (result: IApiResponse<null>) => {
        return result.statusCode;
      },
      invalidatesTags: [
        { type: EAccount.TagsType.USER, id: EAccount.IdsType.CURRENT },
      ],
    }),
    uploadAvatar: build.mutation<IUser.user, IAccount.PUploadAvatar>({
      query({ avatar, userId }) {
        return {
          url: `/users/upload-avatar/${userId}`,
          method: 'PATCH',
          body: avatar,
        };
      },
      transformResponse: (result: IApiResponse<IUser.user>) => {
        return result.data;
      },
      invalidatesTags: [
        { type: EAccount.TagsType.USER, id: EAccount.IdsType.CURRENT },
      ],
    }),
    getUserActivity: build.query({
      query() {
        return {
          url: `/users/coach/history-phone-login-check`,
          method: 'GET',
        };
      },
      transformResponse: (result: IApiResponse<any>) => {
        return result.data;
      },
    }),
  }),
});

export const {
  useGetMeQuery,
  usePatchPersonalDataMutation,
  useUpdateUserMutation,
  useUpdateUserAgreementMutation,
  useUploadAvatarMutation,
  useGetUserActivityQuery,
} = accountApi;
