/* eslint-disable @typescript-eslint/no-explicit-any */
import { v4 as uuidv4 } from 'uuid';
import { UserRoleEnum } from '@/shared/role.enum';
import { TNavItem, TNavItemWithChildren } from './types/nav-item.type';
import { INavigation } from './interfaces/navigation.interface';

export class NavigationMatcher {
  private routes: Map<string, TNavItem>;
  private router: { [key: string]: string } | undefined;
  private defaultRoles: UserRoleEnum[] = [];
  private basepath = '';
  children: any;

  constructor({ basepath, defaultRoles, children = [] }: INavigation.Create) {
    this.basepath = basepath;
    this.defaultRoles = defaultRoles;
    this.routes = this.generateRoutesMap(children);
  }

  private generateRoutesMap(
    routes: Omit<TNavItem, 'id'>[],
  ): Map<string, TNavItem> {
    const map = new Map<string, TNavItem>();
    routes.forEach(route => {
      const id = this.generateId(route.key);
      const meta = route.meta || <INavigation.Meta>{};
      meta.roles = meta.roles || [...this.defaultRoles];
      map.set(route.key, {
        ...route,
        id,
        meta,
        children: route.children || [],
      });
    });
    return map;
  }

  public push(route: Omit<TNavItem, 'id'>) {
    const id = this.generateId(route.key);
    const meta = route.meta || { roles: [...this.defaultRoles] };
    this.routes.set(route.key, {
      ...route,
      id,
      meta,
      children: route.children || [],
    });
  }

  private generateId(key: string): string {
    return `${key}-${uuidv4()}`;
  }

  public getAllRoutes(): TNavItem[] {
    return Array.from(this.routes.values());
  }

  public findRouteByKey(key: string): TNavItemWithChildren | undefined {
    const route = this.routes.get(key);
    if (!route) return undefined;

    if (
      route.children &&
      Array.isArray(route.children) &&
      typeof route.children[0] === 'string'
    ) {
      const children = this.findRoutesByKeys(<string[]>route.children);
      return { ...route, children };
    }

    return <TNavItemWithChildren>(<unknown>route);
  }

  public findRouteByHref(href: string): TNavItemWithChildren | undefined {
    const normalizedHref = this.normalizeHref(href);

    const route = Array.from(this.routes.values()).find(
      route => route.href === normalizedHref,
    );
    if (!route) return undefined;

    if (
      route.children &&
      Array.isArray(route.children) &&
      route.children.length > 0
    ) {
      const children = this.findRoutesByKeys(<string[]>route.children);
      return { ...route, children };
    }

    return <TNavItemWithChildren>(<unknown>route);
  }

  public getRouteName(key: string): string | undefined {
    const route = this.findRouteByKey(key);
    return route ? route.name : undefined;
  }

  public findRoute(): TNavItemWithChildren | undefined {
    if (!this.router) {
      throw new Error('Router is not set up. Call `adjustPathname()` first.');
    }
    const pathname = this.router.pathname;
    return this.findRouteByHref(pathname);
  }

  public isActiveRoute(href: string): boolean {
    if (!this.router) {
      throw new Error('Router is not set up. Call `adjustPathname()` first.');
    }

    const normalizedHref = this.normalizeHref(href);
    const normalizedPathname = this.normalizeHref(this.router.pathname);

    return normalizedPathname === normalizedHref;
  }

  private updateActiveRoute(pathname: string) {
    const normalizedPathname = this.normalizeHref(pathname);

    this.routes.forEach((route, key) => {
      const isActive = this.normalizeHref(route.href) === normalizedPathname;
      let isActiveParent = false;

      if (route.children && route.children.length > 0) {
        const children = this.findRoutesByKeys(<string[]>route.children);
        isActiveParent = children.some(
          child => this.normalizeHref(child.href) === normalizedPathname,
        );
      }

      this.routes.set(key, { ...route, isActive, isActiveParent });
    });
  }

  public adjustPathname(pathname: string) {
    this.router = { pathname };
    this.updateActiveRoute(pathname);
  }

  public findRoutesByKeys(keys: string[]): TNavItemWithChildren[] {
    return keys
      .map(key => this.findRouteByKey(key))
      .filter((route): route is TNavItemWithChildren => !!route);
  }

  public getBasepath(): string {
    return this.basepath;
  }

  public hasRoleInRoute(key: string, role: UserRoleEnum): boolean {
    const route = this.findRouteByKey(key);
    if (!route || !route.meta || !route.meta.roles) {
      return false;
    }
    return route.meta.roles.includes(role);
  }

  public hasAnyRoleInRoute(key: string, roles: UserRoleEnum[]): boolean {
    const route = this.findRouteByKey(key);
    if (!route || !route.meta || !route.meta.roles) {
      return false;
    }
    return roles.some(role => route?.meta?.roles?.includes(role));
  }

  public getRouteMeta(key: string): TNavItem['meta'] | undefined {
    const route = this.findRouteByKey(key);
    return route ? route.meta : undefined;
  }

  private normalizeHref(href: string): string {
    const regex = new RegExp(`^${this.basepath.replace('[lang]', '[a-z]{2}')}`);
    return href.replace(regex, '/');
  }

  public getRouteHref(key: string): TNavItem['href'] | undefined {
    const route = this.findRouteByKey(key);
    return route ? route.href : undefined;
  }

  public getBreadcrumbs(): TNavItemWithChildren[] {
    if (!this.router) {
      throw new Error('Router is not set up. Call `adjustPathname()` first.');
    }

    const pathname = this.normalizeHref(this.router.pathname);
    const segments = pathname.split('/').filter(Boolean);

    const breadcrumbs: TNavItemWithChildren[] = [];
    let currentPath = '';

    for (const segment of segments) {
      currentPath += `/${segment}`;
      const route = this.findRouteByHref(currentPath);
      if (route) {
        breadcrumbs.push(route);
      }
    }

    return breadcrumbs;
  }
}
