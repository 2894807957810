import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { appState } from './state';
import { IApp } from './interfaces/app.interface';

export const slice = createSlice({
  initialState: appState,
  name: 'app',
  reducers: {
    reset: () => appState,
    toggleLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setServerError: (state, action: PayloadAction<boolean>) => {
      state.serverError = action.payload;
    },
    setClientError: (state, action: PayloadAction<IApp.ClientError>) => {
      const { key, data } = action.payload;
      state.clientErrors = { ...state.clientErrors, [key]: data };
    },
    deleteClientError: (state, action: PayloadAction<string>) => {
      const key = action.payload;
      // eslint-disable-next-line no-unused-vars
      const { [key]: _, ...remainingErrors } = state.clientErrors;
      state.clientErrors = remainingErrors;
    },
  },
});

export default slice.reducer;

export const {
  reset,
  toggleLoading,
  setServerError,
  setClientError,
  deleteClientError,
} = slice.actions;
