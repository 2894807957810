import { IApp } from './interfaces/app.interface';

export interface IAppState {
  loading: boolean;
  serverError: boolean;
  clientErrors: { [key: string]: IApp.ClientErrorData };
}

export const appState: IAppState = {
  loading: false,
  serverError: false,
  clientErrors: {},
};
