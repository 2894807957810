import { IUser } from '@/store/api/users/interfaces/user.interface';

interface IUserState {
  user: IUser.user | null;
}

const initialState: IUserState = {
  user: null,
};

export default initialState;
