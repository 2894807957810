import Cookies from 'js-cookie';

export class TokenService {
  private static ACCESS_TOKEN_KEY = 'accessToken';
  private static REFRESH_TOKEN_KEY = 'refreshToken';

  static setTokens(accessToken: string, refreshToken: string) {
    if (typeof window !== 'undefined') {
      Cookies.set(this.ACCESS_TOKEN_KEY, accessToken);
      Cookies.set(this.REFRESH_TOKEN_KEY, refreshToken);
    }
  }

  static getAccessToken(): string | null {
    if (typeof window !== 'undefined') {
      const accessKey = Cookies.get(this.ACCESS_TOKEN_KEY);
      return accessKey ? accessKey : null;
    }
    return null;
  }

  static getRefreshToken(): string | null {
    if (typeof window !== 'undefined') {
      const refreshToken = Cookies.get(this.REFRESH_TOKEN_KEY);

      return refreshToken ? refreshToken : null;
    }
    return null;
  }

  static updateAccessToken(accessToken: string) {
    if (typeof window !== 'undefined') {
      Cookies.set(this.ACCESS_TOKEN_KEY, accessToken);
    }
  }

  static clearTokens() {
    if (typeof window !== 'undefined') {
      Cookies.remove(this.ACCESS_TOKEN_KEY);
      Cookies.remove(this.REFRESH_TOKEN_KEY);
    }
  }
}
