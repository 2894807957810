import { createSlice, PayloadAction } from '@reduxjs/toolkit';

/**
 * Interface
 * */
import { IUser } from '../../api/users/interfaces/user.interface';

/**
 * State
 * */
import state from './state';

export const slice = createSlice({
  initialState: state,
  name: 'userSlice',
  reducers: {
    logout: () => state,
    setUser: (state, action: PayloadAction<IUser.user>) => {
      state.user = action.payload;
    },
  },
});

export default slice.reducer;

export const { logout, setUser } = slice.actions;
