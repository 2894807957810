'use client';

import {
  BaseQueryApi,
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query';
import { Mutex } from 'async-mutex';
import { logout } from '../features/user/slice';
import {
  setServerError,
  setClientError,
  toggleLoading,
} from '../features/app/slice';
import { IApiResponse, IServerError } from '@/shared/types/app';
import { TokenService } from '@/shared/tokens/tokens.service';
import { IAuth } from './auth/interfaces/auth.interface';
import { ENavigationKeys } from '@/navigation/enums/navigation-keys.enum';

const mutex: Mutex = new Mutex();

const glAPI = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_SERVER_ENDPOINT}`,
  credentials: 'include',
  prepareHeaders(headers: Headers) {
    const accessToken = TokenService.getAccessToken();
    if (accessToken) {
      headers.set('Authorization', `Bearer ${accessToken}`);
    }
    return headers;
  },
});

const handleError = (
  statusCode: number | undefined,
  api: BaseQueryApi,
  route: string,
  message: string[],
) => {
  if (statusCode === 401 || !statusCode) {
    TokenService.clearTokens();
    api.dispatch(logout());
  } else if (statusCode >= 500) {
    api.dispatch(setServerError(true));
  } else if (statusCode >= 400 && statusCode < 500) {
    api.dispatch(
      setClientError({ key: route, data: { statusCode, messages: message } }),
    );
  }
};

const customFetchBase: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  api.dispatch(toggleLoading(true));
  const route = window.location.pathname;
  await mutex.waitForUnlock();

  let result = await glAPI(args, api, extraOptions);

  if (route.includes(ENavigationKeys.SignIn)) {
    api.dispatch(toggleLoading(false));
    return result;
  }

  const statusCode = (result.error?.data as IServerError)?.statusCode;
  const message = (result.error?.data as IServerError)?.message;
  const refreshToken = TokenService.getRefreshToken();

  if (statusCode === 401 && refreshToken) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      try {
        const refreshResult = await glAPI(
          { url: `auth/refreshToken?refreshToken=${refreshToken}` },
          api,
          extraOptions,
        );

        if (refreshResult?.data) {
          const { data } = <IApiResponse<IAuth.RefreshTokenResponse>>(
            refreshResult.data
          );
          const { accessToken, refreshToken } = data;
          TokenService.setTokens(accessToken, refreshToken);
          result = await glAPI(args, api, extraOptions);
        } else {
          handleError(401, api, route, message);
        }
      } finally {
        release();
      }
    } else {
      await mutex.waitForUnlock();
      result = await glAPI(args, api, extraOptions);

      if (result.error) {
        const statusCode = (result.error?.data as IServerError)?.statusCode;
        const message = (result.error?.data as IServerError)?.message;
        if (statusCode) {
          handleError(statusCode, api, route, message);
        }
      }
    }
  } else if (result.error) {
    handleError(statusCode, api, route, message);
  }

  api.dispatch(toggleLoading(false));
  return result;
};

export default customFetchBase;
