export enum ENavigationKeys {
  Root = '',
  Brand = 'brand',
  Home = 'home',
  Pricing = 'pricing',
  Contact = 'contact',
  Dashboard = 'dashboard',
  Settings = 'settings',
  SettingsGeneral = 'settingsGeneral',
  SettingsPassword = 'settingsPassword',
  Earnings = 'earnings',
  PrivacyPolicy = 'privacy-policy',
  TermsOfService = 'terms-of-service',
  SignIn = 'sign-in',
  SignOut = 'sign-out',
  SignUp = 'sign-up',
  Connect = 'connect',
  Billing = 'billing',
  BillingHistory = 'billingHistory',
  BillingSubscription = 'billingSubscription',
  ForgotPassword = 'forgotPassword',
  EmailSuccess = 'email-success',
  PasswordResetSuccess = 'password-reset-success',
  PrisingPage = 'pricing-page',
}
